// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-flashylight-privacy-policy-js": () => import("./../../../src/pages/flashylight-privacy-policy.js" /* webpackChunkName: "component---src-pages-flashylight-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tea-o-meter-privacy-policy-js": () => import("./../../../src/pages/tea-o-meter-privacy-policy.js" /* webpackChunkName: "component---src-pages-tea-o-meter-privacy-policy-js" */),
  "component---src-posts-2014-06-16-welcome-to-my-blog-md": () => import("./../../../src/posts/2014-06-16-welcome-to-my-blog.md" /* webpackChunkName: "component---src-posts-2014-06-16-welcome-to-my-blog-md" */),
  "component---src-posts-2014-07-03-getting-started-with-android-application-development-index-md": () => import("./../../../src/posts/2014-07-03-getting-started-with-android-application-development/index.md" /* webpackChunkName: "component---src-posts-2014-07-03-getting-started-with-android-application-development-index-md" */),
  "component---src-posts-2014-07-12-getting-started-with-android-android-development-android-studio-edition-pt-3-index-md": () => import("./../../../src/posts/2014-07-12-getting-started-with-android-android-development-android-studio-edition-pt-3/index.md" /* webpackChunkName: "component---src-posts-2014-07-12-getting-started-with-android-android-development-android-studio-edition-pt-3-index-md" */),
  "component---src-posts-2014-07-12-getting-started-with-android-development-index-md": () => import("./../../../src/posts/2014-07-12-getting-started-with-android-development/index.md" /* webpackChunkName: "component---src-posts-2014-07-12-getting-started-with-android-development-index-md" */),
  "component---src-posts-2014-07-23-getting-started-on-nodejs-md": () => import("./../../../src/posts/2014-07-23-getting-started-on-nodejs.md" /* webpackChunkName: "component---src-posts-2014-07-23-getting-started-on-nodejs-md" */),
  "component---src-posts-2014-08-22-java-related-changes-since-java-se-6-md": () => import("./../../../src/posts/2014-08-22-java-related-changes-since-java-se-6.md" /* webpackChunkName: "component---src-posts-2014-08-22-java-related-changes-since-java-se-6-md" */),
  "component---src-posts-2014-09-19-openshift-cas-quickstart-released-md": () => import("./../../../src/posts/2014-09-19-openshift-cas-quickstart-released.md" /* webpackChunkName: "component---src-posts-2014-09-19-openshift-cas-quickstart-released-md" */),
  "component---src-posts-2015-08-10-recap-on-the-how-to-build-a-startup-on-udacity-md": () => import("./../../../src/posts/2015-08-10-recap-on-the-how-to-build-a-startup-on-udacity.md" /* webpackChunkName: "component---src-posts-2015-08-10-recap-on-the-how-to-build-a-startup-on-udacity-md" */),
  "component---src-posts-2020-08-16-a-gentle-introduction-to-mulesoft-anypoint-platform-index-md": () => import("./../../../src/posts/2020-08-16-a-gentle-introduction-to-mulesoft-anypoint-platform/index.md" /* webpackChunkName: "component---src-posts-2020-08-16-a-gentle-introduction-to-mulesoft-anypoint-platform-index-md" */),
  "component---src-posts-2020-08-28-my-journey-towards-aws-certifications-md": () => import("./../../../src/posts/2020-08-28-my-journey-towards-aws-certifications.md" /* webpackChunkName: "component---src-posts-2020-08-28-my-journey-towards-aws-certifications-md" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

